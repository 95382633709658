<template>
  <div>
    <div class="input_info">
      <p class="input_text">货号：</p>
      <el-input
        v-model="requestParams.item_no"
        placeholder="请输入商品货号"
        size="small"
        clearable
      />
    </div>

    <div class="table_style" style="margin-top: 30px">
      <el-table
        ref="multipleTable"
        header-align="center"
        border
        class="main-table"
        :data="goodsData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="us_num" label="库存" align="center" />
        <el-table-column prop="carts_num" label="购物车占用" align="center" />
        <el-table-column prop="order_num" label="订单占用" align="center" />
      </el-table>
    </div>
    <div class="table_style">
      <div style="padding: 20px"><span>购物车占用</span></div>
      <el-table
        ref="multipleTable"
        header-align="center"
        border
        class="main-table"
        :data="cartListData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="id" label="购物车id" align="center" width="80"/>
        <el-table-column prop="item_no" label="货号" align="center" />
        <el-table-column prop="item_name" label="产品名称" align="center" />
        <el-table-column prop="num" label="数量" align="center" />
        <el-table-column prop="price" label="单价" align="center" />
        <el-table-column prop="country" label="国家" align="center" >
          <template slot-scope="{row}">
            <span v-for="value in countryList" :key="value.code">
              {{ row.country === value.code ? value.name : ''}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="hd_code" label="门店代码" align="center" />
        <el-table-column prop="username" label="门店" align="center" />
        <el-table-column prop="created_at" label="加入时间" align="center">
          <template slot-scope="{ row }">
            <span>{{
                row.created_at
                  ? new Date(row.created_at * 1000).toLocaleString("zh-Hans-CN")
                  : "-"
              }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="updated_at" label="更新时间" align="center">
          <template slot-scope="{ row }">
            <span>{{
                row.updated_at
                  ? new Date(row.updated_at * 1000).toLocaleString("zh-Hans-CN")
                  : "-"
              }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="table_style">
      <div style="padding: 20px"><span>订单占用</span></div>
      <el-table
        ref="multipleTable"
        header-align="center"
        border
        class="main-table"
        :data="orderGoodsList"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="orderid" label="订单id" align="center" width="80"/>
        <el-table-column prop="item_name" label="货号" align="center" />
        <el-table-column prop="item_name" label="产品名称" align="center" />
        <el-table-column prop="num" label="数量" align="center" />
        <el-table-column prop="price" label="单价" align="center" />
        <el-table-column prop="country" label="国家" align="center" >
          <template slot-scope="{row}">
            <span v-for="value in countryList" :key="value.code">
              {{ row.country === value.code ? value.name : ''}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="hd_code" label="门店代码" align="center" />
        <el-table-column prop="username" label="门店" align="center" />
        <el-table-column prop="created_at" label="加入时间" align="center">
          <template slot-scope="{ row }">
            <span>{{
                row.created_at
                  ? new Date(row.created_at * 1000).toLocaleString("zh-Hans-CN")
                  : "-"
              }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="updated_at" label="更新时间" align="center">
          <template slot-scope="{ row }">
            <span>{{
                row.updated_at
                  ? new Date(row.updated_at * 1000).toLocaleString("zh-Hans-CN")
                  : "-"
              }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      requestParams: {
        item_no: ""
      },
      goodsData: [],
      cartListData: [],
      orderGoodsList: [],
      countryList:[]
    };
  },
  watch: {
    "requestParams.item_no"() {
      this.getOccupyList();
    }
  },
  created() {

    this.$axios.get("/a1/country").then((res) => {
      this.countryList = res.data.data;
    });
    this.getOccupyList()

  },
  methods: {
    //获取到客户列表
    getOccupyList() {
      this.goodsData = [];
      if (this.requestParams.item_no){
        this.$axios
          .get(`/a1/goods/inventory/${this.requestParams.item_no}`)
          .then((res) => {
            const { data } = res.data;
            this.goodsData = [data.goods_info];
            this.cartListData = data.cart_list;
            this.orderGoodsList = data.order_goods_list;
          });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.input_info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 300px;
  margin: 10px 0 10px 10px;
}

.input_text {
  width: 80px;
  color: #889aa4;
}

.footer {
  margin: 100px;
  text-align: center;
}

.table_style {
  margin-left: 10px;
}
</style>
